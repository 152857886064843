// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'reviews',
  initialState: {
    reviewsData: [],
    reviewsBySMEIDData: [],
    reviewsName:"Reviews"
  },
  reducers: {
    reviewsData: (state, action) => {
      state.reviewsData = action.payload
    },
    reviewsBySMEIDData: (state, action) => {
      state.reviewsBySMEIDData = action.payload
    },
    reviewsName: (state, action) => {
      state.reviewsName = action.payload
    },
    
}
})

export const { reviewsData, reviewsName, reviewsBySMEIDData} = userSlice.actions

export default userSlice.reducer