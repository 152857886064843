// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'enterprise',
  initialState: {
    enterpriseData: [],
    subscriptionData: [],
    categoriesData: [],
    enterpriseName:"Enterprise"
  },
  reducers: {
    enterpriseData: (state, action) => {
      state.enterpriseData = action.payload
    },
    categoriesData: (state, action) => {
      state.categoriesData = action.payload
    },
    subscriptionData: (state, action) => {
      state.subscriptionData = action.payload
    },
    enterpriseName: (state, action) => {
      state.enterpriseName = action.payload
    },
    
}
})

export const { enterpriseData, enterpriseName, subscriptionData, categoriesData} = userSlice.actions

export default userSlice.reducer