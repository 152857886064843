// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"

import auth from "./authentication"
import userRedux from "./userRedux"
import nav from './navigation'
import smeRedux from "./smeRedux"
import packageRedux from "./packageRedux"
import TopicsKeysRedux from "./TopicsKeysRedux"
import reviewsRedux from "./reviewsRedux"
import enterpriseRedux from "./enterpriseRedux"
import departmentRedux from "./departmentRedux"
import managerRedux from "./managerRedux"
import enterpriseUserRedux from "./enterpriseUserRedux"
import extraRedux from "./extraRedux"

const rootReducer = { 
    navbar, 
    layout,
    auth,
    nav,

    userRedux,
    smeRedux,
    packageRedux,
    TopicsKeysRedux,
    reviewsRedux,
    enterpriseRedux,
    departmentRedux,
    managerRedux,
    enterpriseUserRedux,
    extraRedux,



 }

export default rootReducer
