// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'manager',
  initialState: {
    managerData: [],
    managerName:"Manager"
  },
  reducers: {
    managerData: (state, action) => {
      state.managerData = action.payload
    },
    managerName: (state, action) => {
      state.managerName = action.payload
    },
    
}
})

export const { managerData, managerName} = userSlice.actions

export default userSlice.reducer