// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'enterpriseUser',
  initialState: {
    enterpriseUserData: [],
    enterpriseUserName:"Enterprise User"
  },
  reducers: {
    enterpriseUserData: (state, action) => {
      state.enterpriseUserData = action.payload
    },
    enterpriseUserName: (state, action) => {
      state.enterpriseUserName = action.payload
    },
    
}
})

export const { enterpriseUserData, enterpriseUserName} = userSlice.actions

export default userSlice.reducer