// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'extra',
  initialState: {
    individualData: [],
    reviewsName:"Reviews"
  },
  reducers: {
    individualData: (state, action) => {
      state.individualData = action.payload
    },
    reviewsName: (state, action) => {
      state.reviewsName = action.payload
    },
    
}
})

export const { individualData, reviewsName, subscriptionData} = userSlice.actions

export default userSlice.reducer