// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'sme',
  initialState: {
    smeData: [],
    smeTitleData: [],
    SmeName:"SME",
    smeTitleName:"SME Title"
  },
  reducers: {
    smeData: (state, action) => {
      state.smeData = action.payload
    },
    mainSmeName: (state, action) => {
      state.SmeName = action.payload
    },
    
    smeTitleData: (state, action) => {
      state.smeTitleData = action.payload
    },
    smeTitleName : (state, action) => {
      state.smeTitleName = action.payload
    }
}
})

export const { smeData, mainSmeName, smeTitleData, smeTitleName } = userSlice.actions

export default userSlice.reducer