// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'topicskeys',
  initialState: {
    topicsKeysData: [],
    topicsKeysName:"Topics and Keywords"
  },
  reducers: {
    topicsKeysData: (state, action) => {
      state.topicsKeysData = action.payload
    },
    topicsKeysName: (state, action) => {
      state.topicsKeysName = action.payload
    },
    
}
})

export const { topicsKeysData, topicsKeysName, } = userSlice.actions

export default userSlice.reducer