// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'package',
  initialState: {
    packagesData: [],
    packagesName:"Package"
  },
  reducers: {
    packagesData: (state, action) => {
      state.packagesData = action.payload
    },
    packagesName: (state, action) => {
      state.packagesName = action.payload
    },
    
}
})

export const { packagesData, packagesName, } = userSlice.actions

export default userSlice.reducer